import { AxiosError } from 'axios';
import { commonHttpClient } from './commonHttpClient';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useToast } from '../../components/molecules/toastMolecules';
import { Admin } from '../../utils/interfaces/Admin';
import { RequestLoginPayload } from '../../utils/interfaces/Auth';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

export const useAuth = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const getAuth = async (): Promise<Admin> => {
    let adminData = {} as Admin;
    await http
      .get('/admins/self')
      .then((response) => {
        adminData = response.data as Admin;
      })
      .catch((err: AxiosError) => {
        console.log('現在ログインしていません:', err.response);
      });
    return adminData;
  };

  const requestLogin = async (
    requestLoginPayload: RequestLoginPayload
  ): Promise<void> => {
    await http
      .post('/auth/login', requestLoginPayload)
      .catch((err: AxiosError) => {
        if (err.response?.status == 400) {
          throw new Error('ユーザ名/パスワードのいずれかに誤りがあります');
        }
      });
  };

  const requestLogout = async (): Promise<void> => {
    console.debug('requestLogout ---1---', dayjs().format('HH:mm:ss-SSS'));
    await http.post('/auth/logout');
    console.debug('requestLogout ---2---', dayjs().format('HH:mm:ss-SSS'));
  };

  const useGetAuth = () => useQuery('Administrator', getAuth);

  // const useRequestLoginMutation = () =>
  //   useMutation(requestLogin, {
  //     onSuccess: async () => {
  //       setTimeout(() => {
  //         queryClient.invalidateQueries('Administrator');
  //       }, 300);
  //     },
  //     onError: (e) => {
  //       if (e instanceof Error) {
  //         showToast('error', 'ログインに失敗しました', e.message, 3000);
  //         throw e;
  //       }
  //     },
  //   });

  const useRequestLoginMutation = () => {
    const location = useLocation(); // 現在のURLを取得
    console.log('useRequestLoginMutation');
    console.log(location);
    return useMutation(requestLogin, {
      onSuccess: async () => {
        // `reset_password` 画面にいる場合は `invalidateQueries` を遅らせる
        if (!location.pathname.includes('reset_password')) {
          setTimeout(() => {
            queryClient.invalidateQueries('Administrator');
          }, 300);
        }
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'ログインに失敗しました', e.message, 3000);
          throw e;
        }
      },
    });
  };

  const useRequestLogoutMutation = () =>
    useMutation(requestLogout, {
      onSuccess: async () => {
        setTimeout(() => {
          queryClient.invalidateQueries('Administrator');
        }, 300);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast('error', 'ログアウトに失敗しました', e.message, 3000);
        }
      },
    });

  return {
    useGetAuth,
    useRequestLoginMutation,
    useRequestLogoutMutation,
  };
};
